import React, {Component} from "react"
import SEO from "utils/Seo"
import Layout from "../../components/Layout/Layout"
import styled from "styled-components"
import {graphql, StaticQuery} from "gatsby"
import {media} from "utils/Media"
import HeadingSpecial from "../../components/shared/HeadingSpecial";
import SnowFlakes from "../../components/shared/SnowFlakes";
import iconDownload from "../../images/icon-download.svg";
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import Paginator from "../../components/shared/Paginator";

const pageSlug = "lesson-plans"
const pageTitle = "Lesson Plans"
const categorySlug = "teaching"
const categoryTitle = "teaching"

const Padding = styled.div`
    padding-left: 15px;
    padding-right: 15px;
`
const Content = styled.div`
    position: relative;
    width: 100%;
    padding-bottom: 2rem;
`
const BoxWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    align-items: stretch;
`

const Box = styled.div`
    filter: drop-shadow(0px 0px 12px rgba(0, 49, 153, 0.2));
    background: white;
    padding: 1rem;
    width: 100%;
    margin: 0.5rem auto;

    @media ${media.sm} {
        margin: 1rem;
        width: calc(50% - 2rem);
    }

    @media ${media.md} {
        padding: 2rem;
    }

    h3 {
        font-family: ${props => props.theme.font.family.special};
        color: ${props => props.theme.colors.navy};
        font-size: 24px;
        margin-bottom: 1rem;
        text-align: center;

        @media ${media.sm} {
            font-size: 30px;
            line-height: 35px;
        }

    }

    p {
        @media ${media.lg} {
             min-height: 85px;
        }

        @media ${media.xl} {
            min-height: 65px;
        }
    }
`

class LessonPlans extends Component {
    constructor(props) {
        super(props);

        this.pageData = {
            pageName: "enrichment-materials",
            siteName: "frozeneducation",
            country: "uk",
            region: "emea"
        }
    }

    componentDidMount() {
        // if (typeof window !== 'undefined' && typeof window.utag !== 'undefined') {
        //     // Tealium
        //     window.utag.view(this.pageData)
        // }
    }

    render() {

        return (
            <Layout categorySlug={categorySlug}
                    category={categoryTitle}
                    currentPage={pageTitle}
                    pageName={pageSlug}
                    mainCategorySlug="teaching-resources"
                    enrichmentHeader>
                <SEO slug={pageSlug} title={pageTitle} description=""/>
                <SnowFlakes>
                    <Content className="enrichment">
                        <HeadingSpecial lines={false} headingTag="h1"
                                        className="py-4 py-md-5 font--bigger"
                                        title={pageTitle}/>

                        <GatsbyImage placeholder="none" image={getImage(this.props.data.TopImageLg)} alt="" title=""
                             className="img-fluid d-none d-lg-block"/>
                        <GatsbyImage placeholder="none" image={getImage(this.props.data.TopImageMd)} alt="" title=""
                             className="img-fluid d-none d-sm-block d-lg-none "/>
                        <GatsbyImage placeholder="none" image={getImage(this.props.data.TopImageXs)} alt="" title=""
                             className="img-fluid d-block d-sm-none"/>
                        <Padding>
                            <p className="font--regular narrow text-center mx-auto my-5">Download our four adaptable
                                lesson
                                plans that explore the process of bringing a story to life on stage.</p>
                            <Paginator/>
                        </Padding>
                        <BoxWrapper>
                            <Box>
                                <h3>From Screen to Stage</h3>
                                <p>Frozen began as an animated feature film before it was adapted into a musical. By
                                    trying their own hand at the process of adaptation, students will learn to think
                                    theatrically and appreciate the creative team’s work.</p>
                                <a href="/pdf/Frozen-Lesson-Plans-From-Screen-to-Stage.pdf" download target="_blank"
                                   className="btn btn--new no-interstitial-check d-flex mx-auto text-uppercase text-white my-3">
                                        <span>
                                    Download PDF
                                    </span>
                                    <img src={iconDownload} alt="" title=""/>
                                </a>
                            </Box>
                            <Box>
                                <h3>Frozen Pictures</h3>
                                <p>In Frozen, love is a powerful force that drives several of the characters’ decisions
                                    throughout the story, causing chain reactions. By physically exploring characters’
                                    actions and reactions at specific plot points, students will develop an
                                    understanding of multiple perspectives.</p>
                                <a href="/pdf/Frozen-Lesson-Plans-Frozen-Pictures.pdf" download target="_blank"
                                   className="btn btn--new no-interstitial-check d-flex mx-auto text-uppercase text-white my-3">
                                        <span>
                                    Download PDF
                                    </span>
                                    <img src={iconDownload} alt="" title=""/>
                                </a>
                            </Box>
                        </BoxWrapper>
                        <BoxWrapper>
                            <Box>
                                <h3>I’m Free</h3>
                                <p>The lyrics of ‘Let It Go’ communicate the strength and freedom Elsa experiences after
                                    years of fearing her powers. By writing their own lyrics, students will explore the
                                    power of their own strengths.</p>
                                <a href="/pdf/Frozen-Lesson-Plans-Im-Free.pdf" download target="_blank"
                                   className="btn btn--new no-interstitial-check d-flex mx-auto text-uppercase text-white my-3">
                                        <span>
                                    Download PDF
                                    </span>
                                    <img src={iconDownload} alt="" title=""/>
                                </a>
                            </Box>
                            <Box>
                                <h3>Little Bit of You, Little Bit of Me</h3>
                                <p>In Frozen, sisters Anna and Elsa work together to create Olaf, a friendly snowman and
                                    playmate, who embodies qualities of each sister. Collaborating in pairs, students
                                    will explore how two people can create art together using qualities and ideas from
                                    each other.</p>
                                <a href="/pdf/Frozen-Lesson-Plans-Little-Bit-of-You.pdf" download target="_blank"
                                   className="btn btn--new no-interstitial-check d-flex mx-auto text-uppercase text-white my-3">
                                        <span>
                                    Download PDF
                                    </span>
                                    <img src={iconDownload} alt="" title=""/>
                                </a>
                            </Box>
                        </BoxWrapper>

                    </Content>
                </SnowFlakes>
            </Layout>
        )
    }
}

const Query = (props) => (
    <StaticQuery
        query={graphql`
            query {
                TopImageLg: file(relativePath: { eq: "TeachingResources/header-lessonplan-1920.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                TopImageMd: file(relativePath: { eq: "TeachingResources/header-lessonplan-991.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                TopImageXs: file(relativePath: { eq: "TeachingResources/header-lessonplan-576.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100)
                    }
                }
            }
		`}
        render={data => (
            <LessonPlans data={data}/>
        )}
    />
)

export default Query
